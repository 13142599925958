import {
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useClipboard,
} from '@chakra-ui/react'
import React, { createElement, useMemo } from 'react'
import { MdShare } from 'react-icons/md'

import shareProviders from 'constants/shareProviders'
import { graphql, useStaticQuery } from 'gatsby'

const MoreShre = ({ label, url }) => {
  const { hasCopied, onCopy } = useClipboard(url)
  return (
    <Popover placement="top" isOpen={hasCopied}>
      <PopoverTrigger>
        <IconButton
          icon={<MdShare size="1.5em" />}
          aria-label={label}
          onClick={async () => {
            try {
              await navigator.share({
                url,
              })
            } catch (err) {
              if (err.name === 'NotAllowedError' || err.message.includes('not a function')) {
                onCopy()
              }
            }
          }}
        />
      </PopoverTrigger>
      <PopoverContent w="auto" bg="blackAlpha.900">
        <PopoverBody color="white">已複製到剪貼簿</PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

const ShareButton = ({ url, image, ...props }) => {
  const { site: { siteMetadata: { siteUrl } } } = useStaticQuery(graphql`
  query ShareButtonQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
  `)
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Button fontSize="1em" {...props} />
      </PopoverTrigger>
      <PopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody>
          <ButtonGroup variant="ghost" colorScheme="quiz">
            {useMemo(
              () =>
                shareProviders.map(({ provider, getUrl, icon }) => (
                  <IconButton
                    key={provider}
                    as={Link}
                    isExternal
                    aria-label={provider}
                    icon={createElement(icon, { size: '1.5em' })}
                    href={getUrl(siteUrl + url)}
                  />
                )),
              [siteUrl, url]
            )}
            <MoreShre label={props.children} url={url} />
          </ButtonGroup>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default ShareButton
