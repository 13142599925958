import React from 'react'
import { Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import Module from './Module'
import Link from 'components/Link'

const Plan = ({ fullpageApi }) => {
  return (
    <Module match={'關於「速配城市」計畫'} noCity fullpageApi={fullpageApi}>
      <Text
        letterSpacing={'3.6px'}
        textAlign={'justify'}
        fontSize={responsive('1em', '1.125em')}
        fontWeight={500}
        color="textColor"
      >
        「速配城市」是由
        <Link href="https://relab.cc" textDecoration="underline">
          RE:LAB
        </Link>
        自主發起的實驗計畫。我們相信最理想的城市沒有標準答案，而是會隨著每個人在意的事而持續演進。因此我們設計了這個互動體驗，希望開啟你尋找心中理想城市的旅程，也用不同的角度來認識自己的現居城市！
        <br />
        <br />
        因為資源有限，我們只先收錄了109個指標的縣市數據<Link target="_blank" textDecoration="underline" href="https://whatthedata.cc/blog/full-ref-citymatch/">（點我看指標的挑選方法與來源）</Link>，同時我們也整理了每個縣市的12大類別指標表現，如果你有任何想給我們的建議、或是單純想告訴我們做得很棒，都歡迎來信和我們聊聊或是訂閱與捐款支持我們的「台灣發生什麼數」計畫！
      </Text>
    </Module>
  )
}

export default Plan
