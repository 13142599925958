import React from 'react'
import loadable from '@loadable/component'
import Module from './Module'
import { Box, Text } from '@chakra-ui/react'
import { responsive } from 'contexts/responsive'
import { ResultButton } from '.'
const Stats = loadable(() => import('./Stats'))

const Care = ({ fullpageApi }) => {
  return (
    <Module
      match={'速配城市時\n大家選了哪些在意的事'}
      noCity
      // minH="100vh"
      fullpageApi={fullpageApi}
    >
      <Text
        color="textColor"
        letterSpacing={'3.6px'}
        fontWeight={'bold'}
        fontSize={responsive('1em', '1.125em')}
      >點擊12類泡泡<br />看看哪些指標最多人在意</Text>
      <Stats />
      <Box mt="1em" textAlign={'center'}>
        <ResultButton onClick={() => fullpageApi?.moveSectionDown()}>看完整測驗說明</ResultButton>
      </Box>
    </Module>
  )
}

export default Care
