import React from 'react'
import loadable from '@loadable/component'
import CityBubbles from 'components/CityBubbles'

import Module from './Module'
import { useMemo } from 'react'
const SameCount = loadable(() => import('./SameCount'))

const MatchCity = ({
    fullpageApi,
    data,
    city
  }) => {
  const hearts = useMemo(() => {
    const values = Object.values(data)
    const points = values.reduce(
      (sum, { details, score }) => sum + score / details?.length / values?.length,
      0
    )
    return Math.round(points / 10) / 2
  }, [data])
  // console.log(data)
  return (
    <Module
      match={'和你最速配的是'}
      city={city}
      href={data ? `/share/match/${city}/${hearts * 2}` : '/'}
      button="分享速配結果"
      see="看現居城市結果"
      chart={
        <CityBubbles
          data={data}
          city={city}
          hearts={hearts}
        />
      }
      sameCount={<SameCount city={city} />}
      fullpageApi={fullpageApi}
    />
  )
}

export default MatchCity
