import React from 'react'
import { Box, Flex, HStack, Stack, Image } from '@chakra-ui/react'
import Text from 'components/Text'
import { responsive } from 'contexts/responsive'
import maxBy from 'lodash/maxBy'
import { AnimatePresence, motion } from 'framer-motion'
import BackgroundImage from 'components/BackgroundImage'
import cities from 'components/CityBubbles/svg/cities'

import allData from '../../../google-sheet/data.json'
import red_line from './red_line.png'
import { ResultButton } from '.'

const MotionImage = motion(Image)

const MatchCityLevel = ({
    fullpageApi,
    result,
    care,
    city
  }) => {
  const values = allData?.filter(d => care?.includes(d.name)).map(val => {
    return { ...val, max: maxBy(val.values, (o) => +o.value) }
  })
  // console.log(allData?.filter(d => care?.includes(d.name)))
  return (
    <Box
      px="2em"
      pt="2em"
      pb="1em"
      color="textColor"
      width="100%"
      textAlign={'center'}
      sx={{
        '@media screen and (max-height: 625px)': {
          fontSize: '0.75em',
        },
        '@media screen and (max-width: 367px)': {
          fontSize: '0.75em',
        },
      }}
    >
      <Box pos={'relative'}>
        <Box
          pos="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          opacity={1}
          filter="drop-shadow(3px 5px 10px #f08f95)"
        >
          <AnimatePresence>
            <MotionImage
              key={city}
              src={cities[city]?.default}
              alt={city}
              pos="absolute"
              left="50%"
              top="-10%"
              width={responsive('45%', '50%')}
              transform="translateX(-50%)"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
            />
          </AnimatePresence>
        </Box>
        <Box
          pos="relative"
        >
          <Text
            color="white"
            letterSpacing={'3.6px'}
            fontSize={responsive('1.125em', '1.25em')}
            whiteSpace="pre-wrap"
            textShadow="1px 0 10px #C63E45"
          >
            和你最速配的是
          </Text>
          <Text
            color="white"
            fontSize={responsive('2.25em', '2.75em')}
            textShadow="1px 0 10px #C63E45"
            fontFamily="serif"
            fontWeight={700}
          >
            {city}
          </Text>
          <Flex my="0.75em">
            {result.slice(1).map(([res], i) => (
              <Box borderLeft={i && '1px solid white'} flex={1} key={i}>
                <Text fontSize={responsive('0.75em', '1em')} textShadow="1px 0 10px #fff">
                  No.
                  <Text as="span" fontFamily={"serif"}>{i + 2}</Text>
                </Text>
                <Text mt="0.375rem" color="white" fontSize={responsive('1em', '1.125em')} textShadow="1px 0 10px #C63E45">{res}</Text>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
      <Box pos="relative">
        <Text
          letterSpacing={'1.8px'}
          fontWeight={'bold'}
          fontSize={responsive('0.75em', '1em')}
        >
          如果想找個地方好好生活，這些城市或許適合你
        </Text>
        {!!values.length && (
          <Box
            px="0.75em"
            py="1em"
            mt={responsive('0.75rem', '1em')}
            borderRadius={'1.25em'}
            bg="rgba(255, 255, 255, 0.55)"
            boxShadow={'1px 1px 10px rgba(196, 62, 69, 0.4)'}
          >
            <Text fontWeight={'bold'} fontSize="1em">
              你最在意的指標中，<br />
              表現最好的城市
            </Text>
            <Box mb="0.125rem" mt={!city && '0.5rem'}>
              <BackgroundImage src={red_line} ratio={551 / 41} />
            </Box>
            <Stack>
              {values.map((val) => (
                <HStack spacing={4} justifyContent="center" alignItems={'center'} key={val.name}>
                  <Text fontWeight={500} color="black" fontSize={responsive('0.875em', '1em')}>{val.name}</Text>
                  <Text fontWeight={'bold'} fontFamily={'serif'} fontSize="1em">{val.max.city}</Text>
                </HStack>
              ))}
            </Stack>
          </Box>
        )}
        <Stack color="black" my={responsive('0.75em', '1em')}>
          <ResultButton width="100%" onClick={() => fullpageApi?.moveSectionDown()}>
            從你在意的指標看速配城市
          </ResultButton>
          <ResultButton width="100%" onClick={() => fullpageApi?.moveTo(3)}>
            從你在意的指標看現居城市
          </ResultButton>
        </Stack>
        <Text fontSize="1em">往下滑看完整城市指標</Text>
      </Box>
    </Box>
  )
}

export default MatchCityLevel
