import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Container, } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import ReactFullpage from '@fullpage/react-fullpage';
// import loadable from '@loadable/component'

import useHeader from 'contexts/header/useHeader'
import Button from 'components/Button'
import BackgroundImage from 'components/BackgroundImage';
import getCityScores from 'utils/getCityScores'
import BlockModule from 'components/BlockModule'

import bg from './res_bg.png'
import MatchCity from './MatchCity'
import LiveCity from './LiveCity'
import Care from './Care'
import Plan from './Plan'
import Contact from './Contact'
import MatchCityLevel from './MatchCityLevel';
import arrow_down from './arrow_down.png'
import { responsive } from 'contexts/responsive';

export const ResultButton = (props) => (
  <Button width="224px" fontSize="1em" {...props} />
)

const sections = [
  MatchCityLevel,
  MatchCity,
  LiveCity,
  Care,
  Plan,
  Contact
]

const Result = ({ location }) => {
  // console.log(location.state?.result)
  const { setHideHeader } = useHeader()
  const [resident, setResident] = useState(location.state?.live)
  const [first, setFirst] = useState(true)
  useEffect(() => {
    setHideHeader(false)
    if (!location.state?.live || !location.state?.result) {
      navigate('/')
    }
  }, [])
  const cityCaredOnly = useMemo(() => getCityScores(location.state?.important), [
    location.state?.important,
  ])
  // console.log(first)
  return location.state?.live && location.state?.result ? (
    <BlockModule
      flexDirection="column"
      bgPos="fixed"
      startPercent={16.7}
      percent={60}
      repeat="repeat-x"
      bg={bg}
      color="rgba(255, 255, 255, 0.4)"
    >
      <ReactFullpage
      //fullpage options
        scrollOverflow={false}
        normalScrollElements=".scrollable-content"
        licenseKey={'FKJ77-R4D2H-K4ZCH-6J207-SRCPO'}
        scrollingSpeed = {1000} /* Options here */
        credits={{ enabled: false }}
        render={({ fullpageApi }) => {
          if (first) {
            fullpageApi?.moveTo(1)
            setTimeout(() => setFirst(false), 1000)
          }
          return (
            <ReactFullpage.Wrapper>
              {sections.map((Comp, i, { length }) => (
                <Container height={'100%'} className="section" key={i}>
                  <Comp
                    data={location.state?.result?.[0]?.[1]?.categories}
                    city={location.state?.result?.[0]?.[0]}
                    liveData={cityCaredOnly[resident]?.categories}
                    resident={resident}
                    live={location.state?.live}
                    care={location.state?.care}
                    result={location.state?.result}
                    setResident={setResident}
                    fullpageApi={fullpageApi}
                  />
                  {i !== (length - 1) && (
                    <Box
                      onClick={() => fullpageApi?.moveSectionDown()}
                      cursor="pointer"
                      width="2em"
                      pos="absolute"
                      left="50%"
                      bottom={responsive(4, 4)}
                      transform="translateX(-50%)"
                    >
                      <BackgroundImage src={arrow_down} ratio={71 / 29} />
                    </Box>
                  )}
                </Container>
              ))}
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </BlockModule>
  ) : null
}

export default Result
