import { BsLine, BsFacebook, BsTwitter } from 'react-icons/bs'

export const SHARE_PROVIDER_FACEBOOK = 'facebook';
// export const SHARE_PROVIDER_INSTAGRAM = 'instagram';
export const SHARE_PROVIDER_TWITTER = 'twitter';
export const SHARE_PROVIDER_LINE = 'line';

const urlSchema = {
  [SHARE_PROVIDER_FACEBOOK]: 'https://www.facebook.com/sharer/sharer.php?u={URL}',
  [SHARE_PROVIDER_TWITTER]: 'https://twitter.com/intent/tweet?url={URL}',
  [SHARE_PROVIDER_LINE]: 'https://social-plugins.line.me/lineit/share?url={URL}',
}

const icons = {
  [SHARE_PROVIDER_FACEBOOK]: BsFacebook,
  [SHARE_PROVIDER_TWITTER]: BsTwitter,
  [SHARE_PROVIDER_LINE]: BsLine,
}

const shareProviders = [
  SHARE_PROVIDER_FACEBOOK,
  SHARE_PROVIDER_TWITTER,
  SHARE_PROVIDER_LINE,
].map(provider => ({
  provider,
  getUrl: url => urlSchema[provider].replace('{URL}', encodeURIComponent(url)),
  icon: icons[provider],
}))

export default shareProviders;
