import React, { useMemo } from 'react'
import { Box, Select } from '@chakra-ui/react'
import { IoTriangleSharp } from 'react-icons/io5'

import { citys } from 'containers/Pair'
import { responsive } from 'contexts/responsive'
import CityBubbles from 'components/CityBubbles'
import Module from './Module'

const twCities = citys.slice(0, citys.length - 1)

const LiveCity = ({ resident, liveData, setResident, live, fullpageApi }) => {
  const hearts = useMemo(() => {
    const values = Object.values(liveData || {})
    const points = values.reduce(
      (sum, { details, score }) => sum + score / details?.length / values?.length,
      0
    )
    return Math.round(points / 10) / 2
  }, [liveData])
  return (
    <Module
      match={'你和現居城市有多速配'}
      city={resident}
      href={liveData ? `/share/live/${resident}/${hearts * 2}` : '/'}
      chart={
        <CityBubbles
          data={liveData}
          city={resident}
          hearts={hearts}
        />
      }
      change={
        <Box
          width={responsive('60%', '52.55%')}
          mx="auto"
          pos="relative"
          color="textColor"
        >
          <Select
            bg="white"
            borderRadius="full"
            placeholder="點擊切換城市"
            textAlign={'center'}
            letterSpacing="1.8px"
            color="textColor"
            fontWeight={700}
            mt="0.375rem"
            onChange={(e) => {
              setResident(e.target.value || live)
            }}
            icon={false}
            height="2em"
          >
            {twCities.map((city) => (
              <option value={city} key={city}>
                {city}
              </option>
            ))}
          </Select>
          <Box
            position={'absolute'}
            right="1.25em"
            top="50%"
            transform={'translateY(-50%)'}
          >
            <Box transform={'rotate(180deg) translateY(-25%)'}>
              <IoTriangleSharp size="0.75em" />
            </Box>
          </Box>
        </Box>
      }
      button="分享城市結果"
      see="看大家選的指標"
      fullpageApi={fullpageApi}
    />
  )
}

export default LiveCity
