import React from 'react'
import { Box, Center, Stack, Text } from '@chakra-ui/react'

import BackgroundImage from 'components/BackgroundImage'

import { responsive } from 'contexts/responsive'
import { ResultButton } from '.'
import re_data from './re_data.png'

const contact = [
  { text: '訂閱台灣發生什麼數', href: 'https://whatthedata.cc/subscribe/' },
  { text: '聯絡我們', href: 'https://whatthedata.cc/about/' },
]

const Contact = () => {
  return (
    <Center
      flexDirection={'column'}
      minH="100vh"
      p="2.5em"
      w="full"
    >
      <Box width="90%" mx="auto">
        <BackgroundImage src={re_data} ratio={280 / 55} />
      </Box>
      <Text
        mt="1.125rem"
        fontSize={responsive('1em', '1.125em')}
        fontWeight={500}
        color="textDarkGray"
        textAlign="justify"
      >
        RE:LAB在2020年開始自主發起「台灣發生什麼數」計畫，希望透過不同有趣的實驗，用「數據」帶大家認識台灣的大小事！
      </Text>
      <Stack mt="2em" textAlign={'center'} spacing={4}>
        {contact.map(({ text, href }, i) => (
          <Box key={i}>
            <ResultButton href={href} width="224px">{text}</ResultButton>
          </Box>
        ))}
      </Stack>
    </Center>
  )
}

export default Contact
