import React, { Fragment } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'

import BackgroundImage from 'components/BackgroundImage'
import { responsive } from 'contexts/responsive'

import ShareButton from 'components/ShareButton'
import { ResultButton } from '.'
import line from './line.png'

const Module = ({
  children,
  match,
  city,
  change,
  button,
  href,
  chart,
  noCity,
  sameCount,
  see,
  fullpageApi,
  ...props
}) => (
  <Box
    pos="relative"
    width="100%"
    px="1.625em"
    pt={responsive("3em", '1em')}
    pb="2em"
    sx={{
      '@media screen and (max-height: 625px)': {
        fontSize: '0.75em',
      },
      '@media screen and (max-width: 367px)': {
        fontSize: '0.75em',
      },
    }}
    {...props}
  >
    <Flex
      h="full"
      pos="relative"
      textAlign={'center'}
      flexDir="column"
      justifyContent="center"
    >
      <Text
        color="white"
        letterSpacing={'3.6px'}
        fontSize={responsive('1.125em', '1.25em')}
        whiteSpace="pre-wrap"
        textShadow="1px 0 10px #C63E45"
      >
        {match}
      </Text>
      <Text
        color="white"
        fontSize={responsive('2.25em', '2.75em')}
        textShadow="1px 0 10px #C63E45"
        fontFamily="serif"
        fontWeight={700}
      >
        {city}
      </Text>
      {change}
      <Box mb="0.5rem" mt={!city && '0.5rem'}>
        <BackgroundImage src={line} ratio={868 / 64} />
      </Box>
      {!noCity && (
        <Fragment>
          <Text
            color="textColor"
            letterSpacing={'3.6px'}
            fontWeight={'bold'}
            fontSize={responsive('1em', '1.125em')}
          >
            點擊12類泡泡看看{city}的表現
            <Text mt="0.25rem" color="textGray" fontSize={responsive('0.75em', '0.875rem')}>
              下方泡泡僅呈現你已選擇的指標表現<br />
              泡泡越大代表該類別指標表現越好
            </Text>
          </Text>
          <Box mb="-1em">{chart}</Box>
          {sameCount}
          <Flex justifyContent={'space-between'} flexWrap="wrap">
            <ResultButton width="48%" as={ShareButton} url={href}>
              {button}
            </ResultButton>
            <ResultButton width="48%" onClick={() => fullpageApi?.moveSectionDown()}>
              {see}
            </ResultButton>
          </Flex>
        </Fragment>
      )}
      {children}
    </Flex>
  </Box>
)

export default Module
